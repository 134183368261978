import React from "react"
import pageHeadingStyle from "../components/pageHeading.module.css"
import pageContentListStyle from "../components/pageContentList.module.css"
import ArticleListItem from "../components/articleListItem"
import classNames from "classnames"

export default ({ productArticles }) => {
  return (
    <article>
      <div className={pageHeadingStyle.header}>
        <h2 className={pageHeadingStyle.header_title}>
          製品アクセシビリティ情報
        </h2>
        <p className={pageHeadingStyle.header_description}>
          製品のアクセシビリティに関する情報を紹介します
        </p>
      </div>
      <ol
        className={classNames(
          pageContentListStyle.article_list,
          pageHeadingStyle.article
        )}
      >
        {productArticles.map(({ node }, index) => (
          <ArticleListItem
            path={node.fields.slug}
            title={node.frontmatter.title}
            tags={node.frontmatter.tags}
            date={node.frontmatter.date}
          />
        ))}
      </ol>
    </article>
  )
}
