import React from "react"
import Products from "../../components/products"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

export default ({ data,location }) => {
  return (
    <Layout path={location.pathname} >
      <SEO title="製品アクセシビリティ情報" />
      <Products productArticles={data.allMarkdownRemark.edges} />
    </Layout>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { fields: { slug: { regex: "/products/" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date
            tags
            title
          }
        }
      }
    }
  }
`
